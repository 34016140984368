import { styled, utilities, css } from '@creditas/stylitas'
import { ActionLink } from '@creditas/button'

const Box = styled.div`
  margin-top: 20px;
  width: 100%;
`

const colorNeutral07 = ({ theme }) => css`
  color: ${utilities.palette.color(theme, 'neutral07')};
`

const CustomActionLink = styled(ActionLink)`
  user-select: none;
  padding: 0;
`

const paragraph = ({ theme }) => css`
  ${utilities.typography.support(theme)};
`

const Description = styled.div`
  margin-top: 14px;
  width: 100%;
  p {
    ${colorNeutral07}
    ${paragraph}
    margin: 0 0 25px 0;

    &:last-child {
      margin: 0;
    }
  }
`

export { Box, CustomActionLink, Description }
