import { styled, utilities, css } from '@creditas/stylitas'

const Box = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 2px;
  margin: 30px 0 40px;
  position: relative;

  .values {
    height: 78%;
    width: 78%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    .values-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      border-radius: 50%;
      box-shadow: 0px 0px 40px 0px rgb(170, 187, 187);
    }
  }

  .circular-chart {
    display: block;
    position: relative;
    z-index: 2;
  }

  .circle-bg {
    fill: none;
    stroke: rgba(235, 235, 235, 0.6);
    stroke-width: 3.5;
  }

  .circle {
    fill: none;
    stroke-width: 3.5;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .circular-chart.green .circle {
    stroke: ${({ theme }) => utilities.palette.color(theme, 'primary04')};
  }

  .circular-chart.gray .circle {
    stroke: ${({ theme }) => utilities.palette.color(theme, 'neutral04')};
  }
`

const labelStyle = ({ theme }) => css`
  ${utilities.typography.support(theme)};
  color: ${utilities.palette.color(theme, 'neutral07')};
  font-weight: 600;
`

const Label = styled.span`
  ${labelStyle}
`

const amountHighlight = ({ theme }) => css`
  ${utilities.typography.h3(theme)};

  @media (max-width: 400px) {
    ${utilities.typography.h5(theme)};
  }
`

const AmountHighlight = styled.strong`
  color: ${(props: any) => utilities.palette.color(props.theme, props.color)};
  ${amountHighlight}
  margin-bottom: 8px;
`

export { Label, Box, AmountHighlight }
