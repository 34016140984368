import {
  ButtonPrimaryTextOnly,
  getTypography,
  styled,
} from '@creditas-ui/react'

const PrimaryButton = styled(ButtonPrimaryTextOnly)`
  min-width: 100%;
  ${getTypography('bodyXlBold')};
`

export { PrimaryButton }
