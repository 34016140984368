import React from 'react'
import { ThemeProvider } from '@creditas/stylitas'
import tokens from '@creditas/tokens'
import { ApolloProvider } from '@apollo/react-hooks'

import { TranslatedErrorBoundary } from '../../components'
import { ApolloClient } from '../../services/bff/apollo-client'
import { ChartCustomProps } from './index.spa'
import { Chart } from './Chart'
import { useGetChartStatus } from '../../services/bff/queries'

class App extends React.Component<ChartCustomProps> {
  componentDidCatch() {}

  render() {
    const { authToken, clientId, handleAdvance } = this.props
    const apolloClient = ApolloClient.getAuthorizedClient(authToken)
    return (
      <TranslatedErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={{ ...tokens }}>
            <Chart
              onAdvance={handleAdvance}
              clientId={clientId}
              request={useGetChartStatus}
            />
          </ThemeProvider>
        </ApolloProvider>
      </TranslatedErrorBoundary>
    )
  }
}

export { App }
