import { styled } from '@creditas/stylitas'

export const Card = styled.div`
  max-width: 392px;
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(238, 238, 238);
  border-radius: 5px;
  padding: 27px 33px 41px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 720px) {
    padding: 24px 25px 32px;
  }

  .hideOnMobile {
    @media (max-width: 800px) {
      display: none;
    }
  }
`

export const TitleBox = styled.h1`
  margin-bottom: 10px;
`
