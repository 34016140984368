import React, { useState } from 'react'
import { Navigation } from '@creditas/icons'
import { Box, CustomActionLink, Description } from './Details.style'

interface Props {
  buttonText: string
}

const Details: React.FC<Props> = ({ buttonText, children, ...props }) => {
  const [opened, setOpened] = useState(false)
  const handleOpened = () => setOpened(!opened)

  const iconActionLink = opened ? Navigation.ChevronUp : Navigation.ChevronDown

  return (
    <Box {...props}>
      <CustomActionLink
        icon={iconActionLink}
        onClick={handleOpened}
        data-testid="details-button"
      >
        {buttonText}
      </CustomActionLink>

      {opened && (
        <Description data-testid="details-description">{children}</Description>
      )}
    </Box>
  )
}

export { Details }
